<template>
  <div class="container nota-print">

    <div class="header hide-print">
      <b-button type="is-primary" icon-left="printer-outline" @click="print">Print</b-button>
    </div>

    <div class="content" v-if="loaded && data">

      <div class="is-relative">
        <p class="bg-text">Petambak</p>
        <div class="is-flex is-justify-content-space-between">
          <div>
            <img src="/img/sgara-logo.png" alt="" class="logo">
            <p>PT Transformasi Digital Laut</p>
            <p>Jl. Setia No. 17, Bekasi 17116</p>
            <p>Email jason@sgara.id</p>
          </div>

          <div class="content-header-info">
            <div class="is-flex">
              <p class="has-text-weight-bold">Surat Jalan</p>
              <p>No. #{{ data.delivery_note_id }}</p>
            </div>
            <div class="is-flex">
              <p>Tanggal Surat Jalan</p>
              <p>{{ $dateFormat(data.created_at) }}</p>
            </div>
            <div class="is-flex">
              <p>Tanggal Panen</p>
              <p>{{ $dateFormat(data.harvest_date) }}</p>
            </div>
            <div class="is-flex">
              <p>Nama Petambak</p>
              <p>{{ commaFormat(data.farmer_name) }}</p>
            </div>
            <div class="is-flex">
              <p>Lokasi Tambak</p>
              <p>{{ commaFormat(data.farmer_location) }}</p>
            </div>
            <div class="is-flex">
              <p>Staf Panen</p>
              <p>{{ data.staff_name }}</p>
            </div>
            <div class="is-flex">
              <p>No. Pol. Kendaraan</p>
              <p>{{ data.car_number_license }}</p>
            </div>
            <div class="is-flex">
              <p>Tujuan</p>
              <p>{{ data.destination }}</p>
            </div>
          </div>
        </div>
        <div class="b-table mt-4" v-if="data">
          <div class="table-wrapper">
            <table class="table is-bordered is-narrow">
              <thead>
              <tr>
                <th>Box</th>
                <th>Nomor Segel</th>
                <th>Nama Petak</th>
                <th>Size</th>
                <th>Quantity (Kg)</th>
                <th>Keterangan</th>
              </tr>
              </thead>
              <tbody v-for="(box, key) in data.delivery_boxes" :key="key">
              <tr v-for="(crop, cropKey) in box.delivery_box_crops" :key="cropKey">
                <td :rowspan="box.total_box" v-if="cropKey === 0">{{ key + 1 }}</td>
                <td :rowspan="box.total_box" v-if="cropKey === 0">
                  <p v-for="(item, sealNumberKey) in box.seal_numbers" :key="sealNumberKey">{{ item }}</p>
                </td>
                <td>{{ crop.name }}</td>
                <td>{{ crop.size }}</td>
                <td>{{ crop.qty }}</td>
                <td>{{ crop.note }}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td class="border-0"></td>
                <td class="border-0"></td>
                <td class="border-0"></td>
                <th class="border-0">Total</th>
                <td class="border-top-0">{{ totalQty }}</td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
<!--        <div class="is-flex is-align-items-center is-justify-content-space-between" style="margin: 1rem 5rem 0 5rem">-->
<!--          <div class="signature">-->
<!--            Staf Panen-->
<!--            <img :src="data.staff_sign" alt="">-->
<!--          </div>-->
<!--          <div class="signature">-->
<!--            Staf Pabrik-->
<!--            <div></div>-->
<!--          </div>-->
<!--        </div>-->
      </div>

      <div class="is-divider my-6 hide-print"></div>
      <div class="pagebreak"></div>

      <div class="is-relative">
        <p class="bg-text">A</p>
        <div class="is-flex is-justify-content-space-between">
          <div>
            <img src="/img/sgara-logo.png" alt="" class="logo">
            <p>PT Transformasi Digital Laut</p>
            <p>Jl. Setia No. 17, Bekasi 17116</p>
            <p>Email jason@sgara.id</p>
          </div>

          <div class="content-header-info">
            <div class="is-flex">
              <p class="has-text-weight-bold">Surat Jalan</p>
              <p>No. #{{ data.delivery_note_id }}</p>
            </div>
            <div class="is-flex">
              <p>Tanggal Surat Jalan</p>
              <p>{{ $dateFormat(data.created_at) }}</p>
            </div>
            <div class="is-flex">
              <p>Tanggal Panen</p>
              <p>{{ $dateFormat(data.harvest_date) }}</p>
            </div>
            <div class="is-flex">
              <p>Nama Petambak</p>
              <p>{{ commaFormat(data.farmer_name) }}</p>
            </div>
            <div class="is-flex">
              <p>Lokasi Tambak</p>
              <p>{{ commaFormat(data.farmer_location) }}</p>
            </div>
            <div class="is-flex">
              <p>Staf Panen</p>
              <p>{{ data.staff_name }}</p>
            </div>
            <div class="is-flex">
              <p>No. Pol. Kendaraan</p>
              <p>{{ data.car_number_license }}</p>
            </div>
            <div class="is-flex">
              <p>Tujuan</p>
              <p>{{ data.destination }}</p>
            </div>
          </div>
        </div>
        <div class="b-table mt-4" v-if="data">
          <div class="table-wrapper">
            <table class="table is-bordered">
              <thead>
              <tr>
                <th>Box</th>
                <th>Nomor Segel</th>
                <th>Nama Petak</th>
                <th>Size</th>
                <th>Quantity (Kg)</th>
                <th>Keterangan</th>
              </tr>
              </thead>
              <tbody v-for="(box, key) in data.delivery_boxes" :key="key">
              
                <tr v-for="(crop, cropKey) in box.delivery_box_crops" :key="cropKey">
                <td :rowspan="box.total_box" v-if="cropKey === 0">{{ key + 1 }}</td>
                <td :rowspan="box.total_box" v-if="cropKey === 0">
                  <p v-for="(item, sealNumberKey) in box.seal_numbers" :key="sealNumberKey">{{ item }}</p>
                </td>
                <td>{{ crop.name }}</td>
                <td :rowspan="box.total_box" v-if="cropKey === 0 && box.note_size === 0">{{ roundDown7(box.average_size) }}</td>
                <td :rowspan="box.total_box" v-if="cropKey === 0 && box.note_size === 0">{{ roundUp7(box.sum_qty) }}</td>
                <td v-if="box.note_size > 0">{{ roundDown7(crop.size) }}</td>
                <td v-if="box.note_size > 0">{{ roundUp7(crop.qty) }}</td>
                <td>{{ crop.note }}</td>
              </tr>

              </tbody>
              <tfoot>
              <tr>
                <td class="border-0"></td>
                <td class="border-0"></td>
                <td class="border-0"></td>
                <th class="border-0">Total</th>
                <td class="border-top-0">{{ totalQtyRoundUp }}</td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
<!--        <div class="is-flex is-align-items-center is-justify-content-space-between" style="margin: 1rem 5rem 0 5rem">-->
<!--          <div class="signature">-->
<!--            Staf Panen-->
<!--            <img :src="data.staff_sign" alt="">-->
<!--          </div>-->
<!--          <div class="signature">-->
<!--            Staf Pabrik-->
<!--            <div></div>-->
<!--          </div>-->
<!--        </div>-->
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'DeliveryNotePrint',
  data: () => {
    return {
      data: null,
      cropIds: [],
      notaPanen: [],
      loaded: false,
    }
  },
  computed: {
    delivery_note_id() {
      return this.$route.params.delivery_note_id
    },

    totalQty() {
      return this.data.delivery_boxes.reduce((a, b) => {
        for (let i = 0; i < b.delivery_box_crops.length; i++) {
          a += b.delivery_box_crops[i].qty
        }
        return a
      }, 0)
    },

    totalQtyRoundUp() {
      return this.data.delivery_boxes.reduce((a, b) => {
        for (let i = 0; i < b.delivery_box_crops.length; i++) {
          a += this.roundUp7(b.delivery_box_crops[i].qty)
        }
        return a
      }, 0)
    },
  },
  created() {
    document.querySelector('html').classList.add('has-background-white')
    this.loadData()
  },
  methods: {
    async print() {
      let hidePrint = document.querySelectorAll('.hide-print')
      for (let i = 0; i < hidePrint.length; i++) {
        hidePrint[i].style.display = 'none'
      }
      window.print()
      for (let i = 0; i < hidePrint.length; i++) {
        hidePrint[i].style.display = ''
      }
    },

    commaFormat (string) {
      return string.split(',').join(', ')
    },

    roundUp7 (value) {
      return Math.ceil(value * 107 / 100)
    },

    roundDown7 (value) {
      return Math.floor(value * 93 / 100)
    },

    ...mapActions('supplyChain', [
      'getDeliveryNoteByID',
      'getNotaPanenByCropID',
    ]),

    loadData() {
      this.$loading()
      this.getDeliveryNoteByID(this.delivery_note_id).then(res => {
        if (res.data) {
          this.data = res.data

          for (let i = 0; i < this.data.delivery_boxes.length; i++) {
            let sum_size = 0
            let sum_qty = 0
            let avg_size = 0
            let note_size = 0
            let box = this.data.delivery_boxes[i]

            box.total_box = box.delivery_box_crops.length
            for (let j = 0; j < box.delivery_box_crops.length; j++) {
              let box_crop = box.delivery_box_crops[j]
              sum_size += box_crop.size
              sum_qty += box_crop.qty
              
              if (box_crop.note != undefined) {
                note_size = box_crop.note.length
              }
              //console.log(box_crop.note)
              if (!this.cropIds.includes(box_crop.crop_id)) this.cropIds.push(box_crop.crop_id)
            }

            box.seal_numbers = box.seal_number.split(',')
            avg_size = sum_size / box.total_box
            box.average_size = avg_size
            box.sum_qty = sum_qty
            box.note_size = note_size
          }

          this.loaded = true
        }
      }).finally(() => {
        this.$loading(false)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.nota-print {
  font-family: Arial,sans-serif;
  padding: 2rem 0;

  .header {
    text-align: right;
    padding-bottom: 2rem;
  }

  .content {
    .logo {
      display: flex;
      height: 48px;
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 0
    }

    .content-header-info {
      > div {
        > p {
          &:first-child {
            width: 180px;
          }

          &:nth-child(2) {
            width: 300px;
          }
        }
      }
    }

    .table {
      font-size: .86rem;
      background-color: transparent;
    }
  }

  .signature {
    display: flex;
    flex-direction: column;

    div, img {
      height: 150px;
      width: 300px;
      object-fit: contain;
      border-bottom: 1px solid rgba(0, 0, 0, .4);
    }
  }

  .bg-text {
    position: absolute;
    z-index: -1;
    font-weight: bold;
    font-size: 84px;
    color: rgba(0,0,0,.08);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    font-family: Arial, sans-serif;
    letter-spacing: 1rem;
    pointer-events: none;
  }
}

.border-0 {
  border: unset;
}

@page {
  size: A4 landscape;
}

@media print {
  @page {
    size: A4 landscape;
  }

  .pagebreak {
    clear: both;
    page-break-before: always;
  }

  html, body {
    width: 297mm;
    height: 210mm;
  }

  * {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
  }

  svg, .avoid-break {
    page-break-inside: avoid;
    break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
  }
}

</style>
